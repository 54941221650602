import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: 'Login' */'../views/Login')
    },

    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: 'Register' */'../views/Register')
    },
    {
        path: '/forgetPwd',
        name: 'ForgetPwd',
        component: () => import(/* webpackChunkName: 'ForgetPwd' */'../views/ForgetPwd')
    },
    {
        path: '/resStoreDetail2',
        name: 'ResStoreDetail2',
        component: () => import('../views/user/resource/ResStoreDetail')
    },
    {
        name: 'ResDelivery',
        path: '/resDelivery',
        component: () => import(/* webpackChunkName: 'User'*/ '../views/ResDelivery'),
    },
    {
        path: '/',
        name: 'Main',
        component: () => import(/* webpackChunkName: 'Main' */'../views/Main'),
        redirect: '/index',
        children: [
            {
                path: '/',
                name: 'Index',
                component: () => import(/* webpackChunkName: 'Index' */'../views/Index')
            },
            {
                path: '/contest',
                name: 'Contest',
                component: () => import(/* webpackChunkName: 'Main' */'../views/Contest')
            },
            {
                path: '/platform',
                name: 'Platform',
                component: () => import(/* webpackChunkName: 'Platform' */'../views/Platform'),
                meta: {
                    keepAlive: true
                }
            },
            {
                path: '/organ',
                name: 'Organ',
                component: () => import(/* webpackChunkName: 'Organ' */'../views/Organ')
            },
            {
                path: '/topic',
                name: 'Topic',
                component: () => import(/* webpackChunkName: 'Topic' */'../views/Topic')
            },
            {
                path: '/topicDetail',
                name: 'TopicDetail',
                component: () => import(/* webpackChunkName: 'TopicDetail' */'../views/TopicDetail')
            },
            {
                path: '/tech',
                name: "Tech",
                component: () => import(/* webpackChunkName: 'Tech' */'../views/Tech')
            },
            {
                path: '/institution',
                name: 'Institution',
                component: () => import(/* webpackChunkName: 'Institution' */'../views/Institution')
            },
            {
                path: '/newsDetail',
                name: 'NewsDetail',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/NewsDetail')
            },
            {
                path: '/newsDetailNoLogin',
                name: 'NewsDetailNoLogin',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/NewsDetailNoLogin')
            },
            {
                path: '/yuanqujianjie',
                name: 'Yuanqujianjie',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Yuanqujianjie.vue')
            },
            {
                path: '/xingdongfangan',
                name: 'Xingdongfangan',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Xingdongfangan.vue')
            },
            {
                path: '/gongshanglianzhichi',
                name: 'Gongshanglianzhichi',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Gongshanglianzhichi.vue')
            },
            {
                path: '/guangzhouzhichi',
                name: 'Guangzhouzhichi',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Guangzhouzhichi.vue')
            },
            {
                path: '/platformcyzq',
                name: 'Platformcyzq',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Platformcyzq.vue')
            },
            {
                path: '/zhengcezhichi',
                name: 'Zhengcezhichi',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Zhengcezhichi.vue')
            },
            {
                path: '/tizhikuorong',
                name: 'Tizhikuorong',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Tizhikuorong.vue')
            },
            {
                path: '/shuzijijian',
                name: 'Shuzijijian',
                component: () => import(/* webpackChunkName: 'NewsDetail' */'../views/zone/minban/Shuzijijian.vue')
            },
            {
                path: '/techRes',
                name: 'TechRes',
                component: () => import(/* webpackChunkName: 'TechRes' */'../views/TechRes')
            },
            {
                path: '/instrument',
                name: 'Instrument',
                component: () => import(/* webpackChunkName: 'Instrument' */'../views/Instrument')
            },
            {
                path: '/industry',
                name: 'Industry',
                component: () => import(/* webpackChunkName: 'Industry' */'../views/zone/minban/Industry.vue')
            },
            {
                path: '/specialIndustry',
                name: 'SpecialIndustry',
                component: () => import(/* webpackChunkName: 'Industry' */'../views/SpecialIndustry')
            },
            {
                path: '/xuqiuzixun',
                name: 'Xuqiuzixun',
                component: () => import(/* webpackChunkName: 'Industry' */'../views/Xuqiuzixun')
            },
            {
                path: '/industryDetail',
                name: 'IndustryDetail',
                component: () => import(/* webpackChunkName: 'IndustryDetail' */'../views/zone/minban/IndustryDetail.vue')
            },
            {
                path: '/industryPlatformDetail',
                name: 'IndustryPlatformDetail',
                component: () => import(/* webpackChunkName: 'IndustryPlatformDetail' */'../views/zone/minban/IndustryPlatformDetail.vue')
            },
            {
                path: '/industryPlatformList',
                name: 'IndustryPlatformList',
                component: () => import(/* webpackChunkName: 'IndustryPlatformList' */'../views/zone/minban/IndustryPlatformList.vue')
            },
            {
                path: '/industryDetailTemp',
                name: 'IndustryDetailTemp',
                component: () => import(/* webpackChunkName: 'IndustryDetailTemp' */ '../views/zone/minban/IndustryDetailTemp.vue')
            },
            {
                path: '/DataResource',
                name: 'DataResource',
                component: () => import(/* webpackChunkName: 'IndustryDetailTemp' */ '../views/DataResource')
            },
            {
                path: '/DataResourceType',
                name: 'DataResourceType',
                component: () => import(/* webpackChunkName: 'IndustryDetailTemp' */ '../views/DataResourceType')
            },
            {
                path: '/training',
                name: 'Training',
                component: () => import(/* webpackChunkName: 'Training' */ '../views/Training')
            },
            {
                path: '/newsListPage',
                name: 'NewsListPage',
                component: () => import(/* webpackChunkName: 'NewsListPage'*/ '../views/NewsListPage')
            },
            {
                path: '/downloadList',
                name: 'DownloadList',
                component: () => import(/* webpackChunkName: 'DownloadList' */'../views/DownloadList')
            },
            {
                path: '/building',
                name: 'Building',
                component: () => import(/* webpackChunkName: 'Building' */'../views/Building')
            },
            {
                path: '/about',
                name: 'About',
                component: () => import(/* webpackChunkName: 'About'*/ '../views/about/Index'),
                redirect: '/about/history',
                children: [
                    {
                        path: 'history',
                        name: 'History',
                        component: () => import(/* webpackChunkName: 'About'*/'../views/about/History')
                    },
                    {
                        path: 'dependent',
                        name: 'Dependent',
                        component: () => import(/* webpackChunkName: 'About'*/'../views/about/Dependent')
                    },
                    {
                        path: 'entry',
                        name: 'Entry',
                        component: () => import(/* webpackChunkName: 'About'*/'../views/about/Entry')
                    },
                    {
                        path: 'manage',
                        name: 'Manage',
                        component: () => import(/* webpackChunkName: 'About'*/'../views/about/Manage')
                    },
                    {
                        path: 'data',
                        name: 'Data',
                        component: () => import(/* webpackChunkName: 'About'*/'../views/about/Data')
                    },
                    {
                        path: 'contact',
                        name: 'Contact',
                        component: () => import(/* webpackChunkName: 'About'*/'../views/about/Contact')
                    }
                ]
            },
            {
                name: 'User',
                path: '/user',
                component: () => import(/* webpackChunkName: 'User'*/ '../views/user/Index'),
                redirect: '/user/baseInfo',
                children: [
                    {
                        name: 'BaseInfo',
                        path: 'baseInfo',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/base/BaseInfo'),
                    },
                    {
                        name: 'PwdSetting',
                        path: 'pwdSetting',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/base/PwdSetting'),
                    },
                    {
                        name: 'MessageMgr',
                        path: 'messageMgr',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/web/MessageMgr'),
                    },
                    {
                        name: 'MessageDetail',
                        path: 'messageDetail',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/MessageDetail'),
                    },
                    {
                        name: 'Collect',
                        path: 'collect',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/web/Collect'),
                    },
                    {
                        name: 'DownloadMgr',
                        path: 'downloadMgr',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/DownloadMgr'),
                    },
                    {
                        name: 'UserBuilding',
                        path: 'userBuilding',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/UserBuilding'),
                    },
                    {
                        name: 'Upgrading',
                        path: 'upgrading',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/Upgrading'),
                    },
                    {
                        name: 'PlatformMgr',
                        path: 'platformMgr',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformMgr'),
                    },
                    {
                        name: 'BaseMaterials',
                        path: 'baseMaterials',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/base/BaseMaterials'),
                    },
                    {
                        name: 'ResStore',
                        path: 'resStore',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResStore'),
                    },
                    {
                        name: 'ResStoreDetail',
                        path: 'resStoreDetail',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResStoreDetail'),
                    },
                    {
                        name: 'ResInsServiceLog',
                        path: 'resInsServiceLog',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResInsServiceLog'),
                    },
                    {
                        name: 'ResDataProve',
                        path: 'resDataProve',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResDataProve'),
                    },
                    {
                        name: 'ResDataProveNotice',
                        path: 'resDataProveNotice',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResDataProveNotice'),
                    },
                    {
                        name: 'ResDataProveSelection',
                        path: 'resDataProveSelection',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResDataProveSelection'),
                    },
                    {
                        name: 'ResDataProveForm',
                        path: 'resDataProveForm',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResDataProveForm'),
                    },
                    {
                        name: 'ResRecordProve',
                        path: 'resRecordProve',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResRecordProve'),
                    },
                    {
                        name: 'ResRecordProveNotice',
                        path: 'resRecordProveNotice',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResRecordProveNotice'),
                    },
                    {
                        name: 'ResRecordProveSelection',
                        path: 'resRecordProveSelection',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResRecordProveSelection'),
                    },
                    {
                        name: 'PlatformCheck',
                        path: 'platformCheck',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformCheck'),
                    },
                    {
                        name: 'YiqiLogOffStateExamine',
                        path: 'yiqiLogOffStateExamine',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/YiqiLogOffStateExamine'),
                    },
                    {
                        name: 'LaboratoryBasicInfoExamine',
                        path: 'laboratoryBasicInfoExamine',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LaboratoryBasicInfoExamine'),
                    },
                    {
                        name: 'AnnualReportExamine',
                        path: 'annualReportExamine',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/AnnualReportExamine'),
                    },
                    {
                        name: 'MyApplyLog',
                        path: 'myApplyLog',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/MyApplyLog'),
                    },
                    {
                        name: 'CompetitionList',
                        path: 'competitionList',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/competition/CompetitionList'),
                    },
                    {
                        name: 'UnitApplyLog',
                        path: 'unitApplyLog',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/UnitApplyLog'),
                    },
                    {
                        name: 'ApplyConfirm',
                        path: 'applyConfirm',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ApplyConfirm'),
                    },
                    {
                        name: 'Objection',
                        path: 'objection',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/Objection'),
                    },
                    {
                        name: 'MyObjection',
                        path: 'myObjection',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/MyObjection'),
                    },
                    {
                        name: 'YearReportCheck',
                        path: 'yearReportCheck',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/YearReportCheck'),
                    },
                    {
                        name: 'ResCheck',
                        path: 'resCheck',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResCheck'),
                    },
                    {
                        name: 'DataCheck',
                        path: 'dataCheck',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/DataCheck'),
                    },
                    {
                        name: 'PlatformManage',
                        path: 'platformManage',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformManage'),
                    },
                    {
                        name: 'LaboratoryBasicInfo',
                        path: 'laboratoryBasicInfo',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LaboratoryBasicInfo'),
                    },
                    {
                        name: 'PlatformChange',
                        path: 'platformChange',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformChange'),
                    },
                    {
                        name: 'PlatformFillIn',
                        path: 'platformFillIn',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformFillIn'),
                    },
                    {
                        name: 'PlatformPages',
                        path: 'platformPages',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformPages'),
                    },
                    {
                        name: 'PlatformPageEdit',
                        path: 'platformPageEdit',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformPageEdit'),
                    },
                    {
                        name: 'PlatformYearReport',
                        path: 'platformYearReport',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformYearReport'),
                    },
                    {
                        name: 'PlatformDataProve',
                        path: 'platformDataProve',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/PlatformDataProve'),
                    },
                    {
                        name: 'DataStore',
                        path: 'dataStore',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/DataStore'),
                    },
                    {
                        name: 'DataCatagoryMgr',
                        path: 'dataCatagoryMgr',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/DataCatagoryMgr'),
                    },
                    {
                        name: 'DataRes',
                        path: 'dataRes',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/DataRes'),
                    },
                    {
                        name: 'OpenTopic',
                        path: 'openTopic',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/web/OpenTopic'),
                    },
                    {
                        name: 'ApiKeyApply',
                        path: 'apiKeyApply',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ApiKeyApply'),
                    },
                    {
                        name: 'DataResProve',
                        path: 'dataResProve',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/DataResProve'),
                    },
                    {
                        name: 'LibAwards',
                        path: 'libAwards',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibAwards'),
                    },
                    {
                        name: 'LibExpert',
                        path: 'libExpert',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibExpert'),
                    },
                    {
                        name: 'LibFile',
                        path: 'libFile',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibFile'),
                    },
                    {
                        name: 'LibMedicine',
                        path: 'libMedicine',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibMedicine'),
                    },
                    {
                        name: 'LibCommittee',
                        path: 'libCommittee',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibCommittee'),
                    },
                    {
                        name: 'LibMeeting',
                        path: 'libMeeting',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibMeeting'),
                    },
                    {
                        name: 'LibPaper',
                        path: 'libPaper',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibPaper'),
                    },
                    {
                        name: 'LibPatent',
                        path: 'libPatent',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibPatent'),
                    },
                    {
                        name: 'LibPerson',
                        path: 'libPerson',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibPerson'),
                    },
                    {
                        name: 'LibProject',
                        path: 'libProject',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibProject'),
                    },
                    {
                        name: 'LibResearch',
                        path: 'libResearch',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibResearch'),
                    },
                    {
                        name: 'LibSoftness',
                        path: 'libSoftness',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibSoftness'),
                    },
                    {
                        name: 'LibStandard',
                        path: 'libStandard',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibStandard'),
                    },
                    {
                        name: 'LibWorks',
                        path: 'libWorks',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/lab/LibWorks'),
                    },
                    {
                        name: 'ResReviewDuplication',
                        path: 'resReviewDuplication',
                        component: () => import(/* webpackChunkName: 'User'*/ '../views/user/resource/ResReviewDuplication'),
                    },

                ]
            },
            {
                name: 'Open',
                path: '/open',
                component: () => import(/* webpackChunkName: 'Open'*/ '../views/open/Open')
            },
            {
                name: 'DataShare',
                path: '/dataShare',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/DataShare')
            },
            {
                name: 'DataDownload',
                path: '/dataDownload',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/DataDownload')
            },
            {
                name: 'PlatformStatus',
                path: '/platformStatus',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/PlatformStatus')
            },
            {
                name: 'ReportDetail',
                path: '/reportDetail',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/ReportDetail')
            },
            {
                name: 'Report',
                path: '/report',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/Report')
            },
            {
                name: 'Statistics',
                path: '/statistics',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/Statistics')
            },
            {
                name: 'Introduce',
                path: '/introduce',
                component: () => import(/* webpackChunkName: 'DataShare'*/ '../views/open/Introduce')
            },

        ]
    },

]
//createWebHistory(process.env.BASE_URL)
const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    base: '/',
    routes
})


router.afterEach((to, from) => {
    let bodySrcollTop = document.body.scrollTop
    if (bodySrcollTop !== 0) {
        document.body.scrollTop = 0
        return
    }
    let docSrcollTop = document.documentElement.scrollTop
    if (docSrcollTop !== 0) {
        document.documentElement.scrollTop = 0
    }
})

export default router
