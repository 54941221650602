<template>
  <el-config-provider :locale="locale">
    <div class="main">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component"  v-if="$route.meta.keepAlive"/>
          </keep-alive>
          <component :is="Component"  v-if="!$route.meta.keepAlive"/>
        </router-view>

        <template v-for="dialog in dialogArray">
          <el-dialog v-model="dialog.isShow"
            :title="dialog.title" 
            :width="dialog.width" 
            :height="dialog.height" 
            :destroy-on-close="true" 
            :top="dialog.top"
            :style="{margin:dialog.top + ' auto'}"
            @close="dialog.closeCallback"
            @opened="dialog.openedCallback">
            <iframe :id="dialog.id" :name="dialog.id" :src="dialog.url" frameborder="no" class="dialog-iframe" :style="{height:dialog.height,width:'100%'}"></iframe>
          </el-dialog>
        </template>
    </div>
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import { ElNotification,ElMessage, ElMessageBox } from 'element-plus';
import {defineComponent, getCurrentInstance} from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import router from './router'

export default defineComponent({
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
      dialogArray: {},
      proxy: getCurrentInstance(),
    }
  },
  mounted(){
    let self = this;
    window.portal = {
      goKu(biFormId,isExclOperate,libTypeStr){
        router.push({name: 'ResStoreDetail', path: '/resStoreDetail', query: {biFormId : biFormId,isExclOperate:isExclOperate,libTypeStr:libTypeStr}})
      },
      closeDialog(id){
       if(self.dialogArray[id]){
          self.dialogArray[id].isShow = false;
          self.dialogArray[id].closeCallback();
       }
      },
      openDialog(id, title, url, data, w, h, onclose) {
        if(!id){
          console.error('缺少ID，不弹窗');
          return;
        }
        if(self.dialogArray[id] && self.dialogArray[id].isShow){
          self.dialogArray[id].isShow = false;
          self.dialogArray[id] = undefined;
        }

        let height = h;
        let top = '2vh';

        if(Number.isInteger(h)){
          height = h + 'px';
        }

        const dialogHeaderHeight = 54;
        let numberHeight = Number.parseInt(height);

        if(height.indexOf('%') >= 0){
          //百分比转成px
          if(numberHeight > 100){ numberHeight = 100; }
          const dialogHeight = document.body.clientHeight * (numberHeight/ 100 );
          const hPx =  dialogHeight - dialogHeaderHeight;
          height = Math.floor(hPx) + 'px';
          top = (document.body.clientHeight - dialogHeight)/2 + 'px';
        }else if(height.indexOf('px') >= 0){
          if( document.body.clientHeight > numberHeight){
            const hPx = numberHeight - dialogHeaderHeight;
            height = hPx + 'px';
            top =  (document.body.clientHeight - numberHeight)/2 + 'px';
          }else{
            height = Math.floor(document.body.clientHeight - dialogHeaderHeight)+'px';
            top = '0px';
          }
        }

        let targetData = Object.assign({}, data);

        self.dialogArray[id] = 
        {
          id: id, 
          title: title, 
          url: url, 
          data: targetData, 
          width: w, 
          top: top,
          height: height, 
          openedCallback: function(){
            
          },
          closeCallback: ()=>{
            try{
              onclose(self.dialogArray[id].data);
              //销毁数据
              delete self.dialogArray[id];
            }catch(err){
              console.error(err);
            }
          }, 
          isShow: true
        };


        // dialogArray[id];
      },
      getDialogData(id){
        if(self.dialogArray[id]){
          return self.dialogArray[id].data;
        }else{
          return {};
        }
      },
      setDialogData(id, data){
        if(self.dialogArray[id]){
          let sourceData = self.dialogArray[id].data;
          self.dialogArray[id].data = Object.assign(sourceData, data);
        }
      },
      openTopDialog(options) {
       
      },
      toast(content) {
        ElMessage(content);
      },
      message(options) {
        self.$msgbox(options);
      },
      notification(options) {
        return ElNotification(
          { title:options.title, 
            message: options.content, 
            duration:options.duration,
            onClick: options.onClick,
            onClose: options.onClose,
            icon: options.icon,
            showClose: options.showClose,
            type: options.type,
            position:options.position
          }
        );
      },
    }
  }
})
</script>

<style scoped lang="less">
    /*变黑白*/
    /** {
        filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
        -webkit-filter: grayscale(100%);
    }*/
.main{
  width: 100%;
  height: 100%;
}
    .dialog-iframe{
      flex: 1;
      overflow: hidden;
      width: 100%;
      background-color: #fff;
    }
    ::v-deep .el-dialog__body {
      padding: 0 !important;
    }
</style>